import { render, staticRenderFns } from "./ObrazacSection.vue?vue&type=template&id=17eb6bbe&scoped=true"
import script from "./ObrazacSection.vue?vue&type=script&lang=js"
export * from "./ObrazacSection.vue?vue&type=script&lang=js"
import style0 from "./ObrazacSection.vue?vue&type=style&index=0&id=17eb6bbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17eb6bbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,RadioComponent: require('/home/node/app/components/ui/RadioComponent.vue').default,Checkbox: require('/home/node/app/components/ui/Checkbox.vue').default})
