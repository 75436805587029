
import EditorList from 'Editors/EditorList'
// import EditorImage from 'Editors/EditorImage'
// import EditorList from 'Editors/EditorList'
import EditorTipTap from 'Editors/EditorTipTap'

export default {
  components: {
    EditorList,
    // EditorImage,
    // EditorList,
    EditorTipTap,
  },
  data() {
    return {
      list: [],
      options: [
        {
          name: 'name',
          label: 'Ime osobe',
          type: 'text',
        },
        {
          name: 'surname',
          label: 'Prezime osobe',
          type: 'text',
        },
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '200x200',
        },
        {
          name: 'position',
          label: 'Pozicija osobe',
          type: 'text',
        },
        {
          name: 'email',
          label: 'EMAIL',
          type: 'text',
        },
        {
          name: 'facebook',
          label: 'Facebook link',
          type: 'text',
        },
        {
          name: 'linkedin',
          label: 'LinkedIn',
          type: 'text',
        },
      ],
      swiperOptions: {
        slidesPerView: 2.5,
        loopedSlides: 1,
        spaceBetween: 10,
        initialSlide: 0,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 5.5,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        },
      }
    }
  },
  mounted() {
    if (this.$refs.list && this.$refs.list.items && this.$refs.list.items.length) {
      this.list = [...this.$refs.list.items]
    }
  },
}
