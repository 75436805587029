
import s0 from '~/.nuxt/gb-cms/sections/AktualnostiListSection'
import s1 from '~/.nuxt/gb-cms/sections/BlogTextSection'
import s2 from '~/.nuxt/gb-cms/sections/ContentImageSection'
import s3 from '~/.nuxt/gb-cms/sections/ObrazacSection'
import s4 from '~/.nuxt/gb-cms/sections/PageContentSection'
import s5 from '~/.nuxt/gb-cms/sections/PageHeroSection'
import s6 from '~/.nuxt/gb-cms/sections/AktualnostiSection'
import s7 from '~/.nuxt/gb-cms/sections/AktualnostiSectionNew'
import s8 from '~/.nuxt/gb-cms/sections/HeroSection'
import s9 from '~/.nuxt/gb-cms/sections/HoempageAchievementsSection'
import s10 from '~/.nuxt/gb-cms/sections/HomepageOnamaSection'
import s11 from '~/.nuxt/gb-cms/sections/HomepageProjectsSection'
import s12 from '~/.nuxt/gb-cms/sections/HomepageVaucherSection'
import s13 from '~/.nuxt/gb-cms/sections/OurClientsSection'
import s14 from '~/.nuxt/gb-cms/sections/ContactSection'
import s15 from '~/.nuxt/gb-cms/sections/SingleNatjecajSection'
import s16 from '~/.nuxt/gb-cms/sections/AboutUsHeroSection'
import s17 from '~/.nuxt/gb-cms/sections/AboutUsPersonelSection'
import s18 from '~/.nuxt/gb-cms/sections/AboutUsStructureSection'
import s19 from '~/.nuxt/gb-cms/sections/AboutUsTextSection'
import s20 from '~/.nuxt/gb-cms/sections/ServicesSection'
import s21 from '~/.nuxt/gb-cms/sections/ProjectsContentSection'
import s22 from '~/.nuxt/gb-cms/sections/ProjectsHeroSection'

const sectionMapper = {
	'aktualnosti-list-section': s0,
	'blog-text-section': s1,
	'content-image-section': s2,
	'obrazac-section': s3,
	'page-content-section': s4,
	'page-hero-section': s5,
	'aktualnosti-section': s6,
	'aktualnosti-section-new': s7,
	'hero-section': s8,
	'hoempage-achievements-section': s9,
	'homepage-onama-section': s10,
	'homepage-projects-section': s11,
	'homepage-vaucher-section': s12,
	'our-clients-section': s13,
	'contact-section': s14,
	'single-natjecaj-section': s15,
	'about-us-hero-section': s16,
	'about-us-personel-section': s17,
	'about-us-structure-section': s18,
	'about-us-text-section': s19,
	'services-section': s20,
	'projects-content-section': s21,
	'projects-hero-section': s22
}

export default sectionMapper
