
/* eslint-disable no-unused-vars,prefer-const */
import EditorTipTap from 'Editors/EditorTipTap'
import Checkbox from "~/components/ui/Checkbox";

export default {
  components: {
    Checkbox,
    EditorTipTap,
  },
  data() {
    return {
      loading: false,
      nameAndSurname: '',
      name: '',
      surname: '',
      surveyId: null,
      survey: null,
      surveySubmit: {},
      email: '',
      questions: null,
      answers: [],
      errors: [],
      mandatoryAnswers: []
    }
  },
  watch: {
   async $isInEditMode(v) {
        if(!v && this.$refs.anketaid && this.$refs.anketaid.computedValue) {
          if (
            this.$refs.anketaid.computedValue.replace(/<\/?[^>]+(>|$)/g, "")
          ) {
            this.surveyId = +this.$refs.anketaid.computedValue.replace(/<\/?[^>]+(>|$)/g, "")
          }
          if(this.surveyId) {
            await this.getSurvey(this.surveyId)
          } else {
            this.$toast.error('Molimo unesite ID od ankete!')
          }
        }
    },
    nameAndSurname(text) {
      if (text.length === 0) {
        this.name = ''
        this.surname = ''
        return false
      }
      if (text && text.length && text.includes(' ')) {
        const nameArray = text.split(' ')
        if (nameArray && nameArray.length && nameArray.length === 2) {
          this.surname = nameArray[1]
          this.name = nameArray[0]
        } else if (nameArray && nameArray.length && nameArray.length > 2) {
          this.surname = nameArray.splice(1, nameArray.length - 1).join(' ')
          this.name = nameArray[0]
        }
      } else {
        this.surname = ''
      }
    },
  },
  async mounted() {
    if(this.$refs.anketaid && this.$refs.anketaid.computedValue) {
      if (
        this.$refs.anketaid.computedValue.replace(/<\/?[^>]+(>|$)/g, "")
      ) {
        this.surveyId = +this.$refs.anketaid.computedValue.replace(/<\/?[^>]+(>|$)/g, "")
      }
    }
    if(this.surveyId) {
      await this.getSurvey(this.surveyId)
    } else {
      this.$toast.error('Molimo unesite ID od ankete!')
    }
  },
  methods: {
    async getSurvey(surveyId) {
      const stableVersion = 'v1'
      const apiPrefix = 'api'
      const marketingModulePrefix = 'marketing'
      const urlObject = {
        apiUrl: 'survey/public/get?id=' + surveyId + '',
        method: 'get',
        modulePrefix: marketingModulePrefix,
        version: stableVersion
      }
      const {version, modulePrefix, method, apiUrl} = urlObject
      const url = `${apiPrefix}/${version}/${modulePrefix}/${apiUrl}`
      try {
        this.loading = true
        const survey = (await this.$axios[method](url)).data.data
        this.loading = false
        if (survey) {
          this.survey = survey
        } else {
          this.survey = null
          if(this.questions && this.questions.length) {
            this.questions.splice(0, this.questions.length)
          }
        }
        if (survey && survey.questions && survey.questions.length) {
          this.questions = [...survey.questions]
        }

      } catch (e) {
        console.log(e)
        this.loading = false
        this.survey = null
        if(this.questions && this.questions.length) {
          this.questions.splice(0, this.questions.length)
        }
        await this.$toast.error(
          'Dogodila se greška prilikom dohvaćanja ankete!'
        )
      }
    },
    pushAnswer(obj, type) {
      let objekt = {}
      objekt = {...obj}
      if (this.survey && this.survey.id) {
        this.surveySubmit.survey = this.survey.id
      }
      if (+type === 2) {
        if (
          !this.answers.find(
            ob => ob.answer === objekt.answer && ob.question === objekt.question
          )
        ) {
          this.answers.push(obj)
        } else {
          this.answers = this.answers.filter(function (obj) {
            return obj.answer !== objekt.answer
          })
        }
      }
      if (+type === 3) {
        let index = -1
        if (!this.answers.find(ob => ob.question === objekt.question)) {
          this.answers.push(obj)
        } else {
          this.answers = this.answers.filter(function (obj) {
            return obj.question !== objekt.question
          })
          this.answers.push(objekt)
        }
      }
      if (+type === 1) {
        let index = -1
        if (!this.answers.find(ob => ob.question === objekt.question)) {
          this.answers.push(obj)
        } else {
          this.answers = this.answers.filter(function (obj) {
            return obj.question !== objekt.question
          })
          this.answers.push(objekt)
        }
      }
      if (+type === 6) {
        const inputValue =
          this.$refs &&
          this.$refs['textarea' + obj.question] &&
          this.$refs['textarea' + obj.question][0] &&
          this.$refs['textarea' + obj.question][0].value
            ? this.$refs['textarea' + obj.question][0].value
            : ''
        let index = -1
        index = this.answers.findIndex(x => x.question === objekt.question)
        if (index === -1 && inputValue.length) {
          this.answers.push({
            question: objekt.question,
            text: inputValue
          })
        } else if (index !== -1 && inputValue.length === 0) {
          this.answers = this.answers.filter(function (obj) {
            return obj.question !== objekt.question
          })
        } else if (index !== 1 && inputValue.length > 0) {
          this.answers[index].text = inputValue
        } else {
          return false
        }
      }

      if (+type === 7) {
        let inputValue =
          this.$refs &&
          this.$refs['input' + obj.question] &&
          this.$refs['input' + obj.question][0] &&
          this.$refs['input' + obj.question][0].value
            ? this.$refs['input' + obj.question][0].value
            : ''
        let index
        index = this.answers.findIndex(x => x.question === objekt.question)
        if (index === -1 && inputValue.length) {
          this.answers.push({
            question: objekt.question,
            text: inputValue
          })
        } else if (index !== -1 && inputValue.length === 0) {
          this.answers = this.answers.filter(function (obj) {
            return obj.question !== objekt.question
          })
        } else if (index !== -1 && inputValue.length > 0) {
          this.answers[index].text = inputValue
        } else {
          return false
        }
      }
      this.answers.map(x => delete x.type)
      this.surveySubmit.answers = this.answers
    },
    async send() {
      this.validateAnswers()
      if (this.errors && this.errors.length || this.answers && !this.answers.length) {
        if (this.$i18n.locale === 'hr') {
          this.$toast.error('Potrebno je ispuniti sva obavezna polja prije slanja upita.')} else {
          this.$toast.error('There was an error sending the message, check all the fields and try again!')
        }
        return false
      }
      let body = {}
      body = {...this.surveySubmit, email: this.email, firstName: this.name, lastName: this.surname}
      const stableVersion = 'v1'
      const apiPrefix = 'api'
      const marketingModulePrefix = 'marketing'
      const urlObject = {
        apiUrl: 'survey/public/submit',
        method: 'post',
        modulePrefix: marketingModulePrefix,
        version: stableVersion
      }
      const {version, modulePrefix, method, apiUrl} = urlObject
      const url = `${apiPrefix}/${version}/${modulePrefix}/${apiUrl}`
      try {
        this.loading = true
        // eslint-disable-next-line no-unused-vars
        const res = (await this.$axios[method](url, body)).data.data
        if (res) {
          this.clearData()
          this.$toast.success('Vaš odgovor je zabilježen! Zahvaljujemo na odgovoru!')
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    validateAnswers() {
      let mappedAnswers = []
      if (
        !this.survey &&
        !this.questions &&
        !this.questions.length
      )
        return false

      // eslint-disable-next-line no-unused-vars
      let mandatoryAnswersID = []
      // eslint-disable-next-line array-callback-return
      mandatoryAnswersID = this.questions.map(el => {
        return el.id
      })
      if (this.answers && this.answers.length) {
        mappedAnswers = this.answers.map(el => {
          return el.question
        })
      }
      if (mandatoryAnswersID && mandatoryAnswersID.length) {
        this.errors = mandatoryAnswersID.filter(
          element => !mappedAnswers.includes(element)
        )
      }
      if (this.email.length === 0) {
        this.errors.push('emailError')
      }
      if (this.nameAndSurname.length === 0) {
        this.errors.push('nameError')
      }
    },
    clearData() {
      this.email = ''
      this.name = ''
      this.surname = ''
      this.nameAndSurname = ''
      this.errors.splice(0, this.errors.length)
      this.surveySubmit = {}
    },
    returnError(id) {
      return !!(this.errors.length && this.errors.includes(id));
    }
  }
}
