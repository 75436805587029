
import EditorImage from 'Editors/EditorImage'

export default {
  components: {
    EditorImage,
  },
  data() {
    return {
      videoPlaying: false,
      inView: false,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov',
          type: 'text',
        },
        {
          name: 'image',
          label: 'SVG slike',
          type: 'text',
        },
      ],
    }
  },
}
