import { render, staticRenderFns } from "./HomepageOnamaSection.vue?vue&type=template&id=e75f7cf6&scoped=true"
import script from "./HomepageOnamaSection.vue?vue&type=script&lang=js"
export * from "./HomepageOnamaSection.vue?vue&type=script&lang=js"
import style0 from "./HomepageOnamaSection.vue?vue&type=style&index=0&id=e75f7cf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e75f7cf6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InView: require('/home/node/app/components/ui/inView.vue').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
