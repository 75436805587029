
import ControllerContactForm from 'Controllers/ControllerContactForm'
import EditorImage from 'Editors/EditorImage'

export default {
  components: {
    EditorImage,
    ControllerContactForm,
  },
  data() {
    return {
      tryToSent: false,
      messageSent: false,
      contactFormName: '',
      options: [
        {
          name: 'address',
          label: 'Adresa',
          type: 'text',
        },
        {
          name: 'telephone_rac_1',
          label: 'Telefon Računovodstvo 1',
          type: 'text',
        },
        {
          name: 'telephone_rac_2',
          label: 'Telefon Računovodstvo 2',
          type: 'text',
        },
        {
          name: 'telephone_rac_3',
          label: 'Telefon Računovodstvo 3',
          type: 'text',
        },
        {
          name: 'eu_telefon',
          label: 'Telefon EU odjela',
          type: 'text',
        },
        {
          name: 'eu_telefon_1',
          label: 'Telefon EU odjela 1',
          type: 'text',
        },
        {
          name: 'workhours_rac',
          label: 'Radno vrijeme računovodstvo:',
          type: 'text',
        },
        {
          name: 'workhours_eu',
          label: 'Radno vrijeme EU odjel:',
          type: 'text',
        },
        {
          name: 'link',
          label: 'E-mail',
          type: 'link',
        },
      ],
    }
  },
  computed: {
    consentCheck() {
      return this.tryToSent && !this.$refs.contactController.form.acceptedConsents.length && this.$refs.contactController.requiredConsentsUniqueNumbers.length
    }
  },
  watch: {
    contactFormName(text) {
      if (text.length === 0) {
        this.$set(this.$refs.contactController.form, 'lastName', '')
        this.$set(this.$refs.contactController.form, 'firstName', '')
        return false
      }
      if (text && text.length && text.includes(' ')) {
        const nameArray = text.split(' ')
        if (nameArray && nameArray.length && nameArray.length === 2) {
          this.$set(this.$refs.contactController.form, 'lastName', nameArray[1])
          this.$set(this.$refs.contactController.form, 'firstName', nameArray[0])
        } else if (nameArray && nameArray.length && nameArray.length > 2) {
          this.$set(this.$refs.contactController.form, 'lastName', nameArray.splice(1, nameArray.length - 1).join(' '))
          this.$set(this.$refs.contactController.form, 'firstName', nameArray[0])
        }
      } else {
        this.$set(this.$refs.contactController.form, 'lastName', '')
      }
    },
    formSurname(v) {
      if (v) {
        if (this.$refs && this.$refs.contactController && this.$refs.contactController.form) {
          this.$set(this.$refs.contactController.form, 'lastName', this.formSurname)
        }
      }
    },
    formName(v) {
      if (v) {
        if (this.$refs && this.$refs.contactController && this.$refs.contactController.form) {
          this.$set(this.$refs.contactController.form, 'firstName', this.formName)
        }
      }
    }
  },
  mounted() {
    this.$watch(() => {
        return this.$refs.contactController && this.$refs.contactController.errorMessageObject
      },
      (obj) => {
        if (obj && !Object.values(obj).every(x => x === '')) {
          if (this.$i18n.locale === 'hr') {
            this.$toast.error('Potrebno je ispuniti sva obavezna polja prije slanja poruke.')
          } else {
            this.$toast.error('All required fields are required before sending the message.')
          }
        }
      },
      {
        deep: true
      }
    )
  },
  methods: {
    formSubmitted() {
      this.messageSent = true;
      this.okay()
    },
    okay() {
      // eslint-disable-next-line eqeqeq
      this.$i18n.locale == "hr"
        ? this.$router.push("/")
        : this.$router.push("/en");
    }
  }
}
