
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: [String, Number]
    },
    // eslint-disable-next-line vue/require-prop-types
    "modelValue": {default: ""},
    "value": {type: String, default: undefined},
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [String, Number],
      default: null
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    changeEvent($event) {
      this.$emit('change', $event.target.value)
    }
  }
}
