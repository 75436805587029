
import EditorTipTap from 'Editors/EditorTipTap'
import EditorImage from 'Editors/EditorImage'

export default {
  components: {
    EditorTipTap,
    EditorImage,
  },
}
