import { render, staticRenderFns } from "./ContactSection.vue?vue&type=template&id=cd22576c&scoped=true"
import script from "./ContactSection.vue?vue&type=script&lang=js"
export * from "./ContactSection.vue?vue&type=script&lang=js"
import style0 from "./ContactSection.vue?vue&type=style&index=0&id=cd22576c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd22576c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,ControllerContactForm: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default})
