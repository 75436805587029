
import VaucherModal from '~/components/modals/VaucherModal'

export default {
  components: {
    VaucherModal,
  },
  data() {
    return {
      inView: false,
      showForm: false,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov',
          type: 'tipTap',
        },
        {
          name: 'image',
          label: 'SVG slike',
          type: 'text',
        },
      ],
    }
  },
}
