
/* eslint-disable prefer-const,no-lonely-if */
export default {
  data() {
    return {
      list: [],
      filteredList: [],
      activeTheme: '',
      activeCategory: '',
      activeTags: [],
      limit: 4,
      pageLoaded: false,
      showAllTags: false,
      currentPage: 1,
      paginationLength: 0,
      themes: [],
      categories: [],
      loading: false,
      tags: [],
      theme: '',
      options: [
        {
          name: 'title',
          label: 'Naslov (*Obavezno)',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov (*Obavezno)',
          type: 'tipTap',
        },
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
          size: '280x184',
        },
        {
          name: 'category',
          label: 'Kategorija (*Obavezno)',
          type: 'text',
        },
        {
          name: 'tagovi',
          label: 'Tagovi (odvojiti tagove sa ";")',
          type: 'text',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
      filter: {}
    }
  },
  computed: {
    paginationElement() {
      let currentPage = this.currentPage
      let paging = []
      let range = 5
      let totalPages = this.paginationLength
      let start = 1

      if (totalPages < range) {
        range = this.paginationLength
      }

      if (currentPage < (range / 2) + 1) {
        start = 1;
      } else if (currentPage >= (totalPages - (range / 2))) {
        start = Math.floor(totalPages - range + 1);
      } else {
        start = (currentPage - Math.floor(range / 2));
      }

      for (let i = start; i <= ((start + range) - 1); i++) {
        if (i === currentPage) {
          paging.push({page: i, item: 'current'}); // add brackets to indicate current page
        } else {
          paging.push({page: i, item: 'notCurrent'});
        }
      }
      if (currentPage > range) {
        paging.unshift({page: null, item: 'dots'})
      }
      if (currentPage <= (totalPages - range)) {
        paging.push({page: null, item: 'dots'})
      }
      return paging;
    }
  },
  watch: {
    $isInEditMode(v) {
      if (!v) {
        this.initialSetup()
        this.changePage()
      }
    },
    activeCategory(v) {
      if (v) {
        this.filterByTags(this.filter)
      }
      this.changeFilterParameters()
    },
    activeTheme(v) {
      if (v) {
        this.filterByTags(this.filter)
      }
      this.changeFilterParameters()
    },
    currentPage() {
      this.scrollToTop()
      this.changePage()
    },
    activeTags: {
      handler(v) {
        this.filteredList = [...this.list]
        const filter = {}
        filter.category = this.activeCategory
        filter.theme = this.activeTheme
        filter.tags = this.tags
        this.filter = {...filter}
        if (v.length === 0) {
          this.changeFilterParameters()
        } else {
          this.filterByTags(this.filter)
          this.setupPagination()
        }
      },
      deep: true
    },
    filteredList: {
      handler(v) {
        function getRandomArbitrary(min, max) {
          return Math.floor(Math.random() * (max - min) + min)
        }

        let rand = getRandomArbitrary(2, 10)
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, rand * 100)
      },
      deep: true
    }
  },
  mounted() {
    this.initialSetup()
    this.changePage()
  },
  methods: {
    returnDate(date) {
      if (!date) return ''
      let variableSpace
      let variableDot
      let finalDate
      variableDot = date.split('.')
      variableSpace = date.split(' ')
      if (variableDot.length === 3) {
        variableDot = {
          firstDay: variableDot[0] || 'dan',
          otherDay: `${variableDot[1]} ${variableDot[2]}` || 'datum'
        }
        finalDate = variableDot
      } else {
        if (variableSpace && variableSpace.length) {
          variableSpace = {
            firstDay: variableSpace[0] + '.' || 'dan',
            otherDay: `${variableSpace[1]} ${variableSpace[2]}` || 'datum'
          }
          finalDate = variableSpace
        }
      }
      return finalDate
    },
    changeFilterParameters() {
      this.currentPage = 1
      this.limit = 4
      this.setFilter()
      this.setupPagination()
    },
    setupPagination() {
      if (this.filteredList.length) {
        this.paginationLength = Math.ceil(this.filteredList.length / this.limit)
      } else {
        this.paginationLength = 0
      }
      this.changePage()
    },
    changePage() {
      this.setFilter()
      if (this.currentPage === 1) {
        this.filteredList = [...this.filteredList.slice((this.currentPage - 1), this.limit)]
      } else {
        this.filteredList = [...this.filteredList.slice((this.limit * (this.currentPage - 1)), this.limit * this.currentPage)]
      }
    },
    pushTagToFilters(tag) {
      let index
      this.currentPage = 1
      if (!this.activeTags.includes(tag)) {
        this.activeTags.push(tag)
      } else {
        index = this.activeTags.findIndex(el => el === tag)
        this.activeTags.splice(index, 1)
      }
    },
    setFilter() {
      const filter = {}
      filter.category = this.activeCategory
      filter.theme = this.activeTheme
      filter.tags = this.tags
      this.filter = {...filter}
      if (this.activeTags.length === 0) {
        this.filterList(this.filter)
      } else {
        this.filterByTags(this.filter)
      }
    },
    initialSetup() {
      if (this.$refs.aktualnostiList && this.$refs.aktualnostiList.items && this.$refs.aktualnostiList.items.length) {
        this.list = [...this.$refs.aktualnostiList.items]
        if (this.list && this.list.length) {
          this.list.reverse()
          // eslint-disable-next-line array-callback-return
          this.list.map((el) => {
            if (el.category && el.category.length) {
              this.categories.push(el.category.trim())
            }
            if (el.theme && el.theme.length) {
              this.themes.push(el.theme.trim())
            }
            if (el && el.tagovi && el.tagovi.length && typeof el.tagovi === 'string') {
              let tags = []
              tags = el.tagovi.split(';')
              if (tags.length) {
                tags.map(el => this.tags.push(el.trim()))
              }
              this.tags = [...this.flatTags(this.tags)]
            }
          })
          this.filteredList = [...this.list]
          // eslint-disable-next-line array-callback-return
          this.filteredList.filter(el => {
            if (el.tags && el.tags.length && typeof el.tags === 'string') {
              el.tags = el.tags.split(';')
            }
          })
        }
        if (this.categories.length) {
          this.categories = [...this.flatCategories(this.categories)]
        }
        if (this.themes.length) {
          this.themes = [...this.flatThemes(this.themes)]
        }
        this.setupPagination()
      }
      setTimeout(() => {
        this.pageLoaded = true
      }, 300)
    },
    flatCategories(list) {
      return list.reduce(function (a, b) {
        if (!a.includes(b)) a.push(b);
        return a;
      }, []);
    },
    flatThemes(themes) {
      return themes.reduce(function (a, b) {
        if (!a.includes(b)) a.push(b);
        return a;
      }, []);
    },
    flatTags(tags) {
      return tags.reduce(function (a, b) {
        if (!a.includes(b) && b.length > 0) a.push(b);
        return a;
      }, []);
    },
    returnNumberOfItems(category) {
      let number = 0
      number = this.list.filter(function (element) {
        return element.category.trim() === category.trim();
      }).length
      return number
    },
    setActiveCategory(cat) {
      this.currentPage = 1
      if (this.activeCategory.trim() === cat.trim()) {
        this.activeCategory = ''
        return false
      }
      this.activeCategory = cat.trim()
    },
    filterList(filter) {
      this.filteredList = [...this.list.filter(item => {
          return Object.entries(filter).every(([key, val]) => {
            if (val !== '' && !Array.isArray(val)) {
              return item[key].trim() === val.trim()
            } else {
              return true
            }
          })
        }
      )]
    },
    filterByTags(filter) {
      if (this.activeTags && this.activeTags.length) {
        this.filteredList = [...this.list.filter(item => {
            return Object.entries(filter).every(([key, val]) => {
              if (val !== '' && !Array.isArray(val)) {
                return item[key].trim() === val.trim()
              } else {
                return true
              }
            })
          }
        )]
        this.filteredList = [...this.filteredList.filter(item => {
            if (item && item.tagovi) {
              return !!(this.intersect(item.tagovi, this.activeTags) && this.intersect(item.tagovi, this.activeTags).length);
            } else {
              return false
            }
          }
        )]
      }
    },
    intersect(a, b) {
      return b.filter(el => a.includes(el.trim()))
    },
    scrollToTop() {
      if (this.pageLoaded) {
        setTimeout(() => {
          this.$refs.listWrap.scrollIntoView()
        }, 500)
      }
    }
  }
}
