
import EditorList from 'Editors/EditorList'
import EditorTipTap from 'Editors/EditorTipTap'

export default {
  components: {
    EditorList,
    EditorTipTap,
  },
  data() {
    return {
      list: [],
      swiperOptions: {
        slidesPerView: 2.3,
        spaceBetween: 10,
        updateOnImagesReady: true,
        loop: true,
        observer: true,
        observeParents: true,
        speed: 1000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        breakpoints: {
          562: {
            slidesPerView: 3.8,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 4.8,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6.8,
            spaceBetween: 60,
          },
        },
        on: {
          slideChange: () => {
            if (this.bottomSlider && this.aktivnosti) {
              this.bottomSlider.slideToLoop(this.aktivnosti.realIndex)
            }
          },
        },
      },
      swiperOptionBottom: {
        slidesPerView: 3,
        spaceBetween: 0,
        updateOnImagesReady: true,
        loop: true,
        slideToClickedSlide: true,
        observer: true,
        freeModeMomentum: true,
        freeMode: true,
        observeParents: true,
        on: {
          click: () => {
            if (this.bottomSlider && this.aktivnosti) {
              this.aktivnosti.slideToLoop(this.bottomSlider.realIndex)
            }
          },
        },
      },
      options: [
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '400x400',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
    }
  },
  watch: {
    $isInEditMode(v) {
      if (!v) {
        this.getData()
        setTimeout(() => {
          this.aktivnosti.updateSlides()
        }, 1000)
      }
    },
  },
  mounted() {
    this.getData()
    setTimeout(() => {
      if (this.bottomSlider && this.aktivnosti) {
        this.aktivnosti.slideToLoop(this.bottomSlider.realIndex)
      }
    }, 300)
  },
  methods: {
    getData() {
      if (
        this.$refs.clientsSectionListRef &&
        this.$refs.clientsSectionListRef.items &&
        this.$refs.clientsSectionListRef.items.length
      ) {
        this.list = [...this.$refs.clientsSectionListRef.items]
      }
    },
  },
}
