
/* eslint-disable prefer-const,no-lonely-if */
import EditorImage from 'Editors/EditorImage'
import EditorList from 'Editors/EditorList'
import EditorLink from 'Editors/EditorLink'

export default {
  components: {
    EditorList,
    EditorLink,
    EditorImage,
  },
  data() {
    return {
      slides: [],
      transition: false,
      activeSlide: 1,
      disableScroll: false,
      isOnTop: true,
      options: [
        {
          name: 'slider_image',
          label: 'Hero image',
          type: 'image',
          size: '1440x680',
        },
        {
          name: 'slider_title',
          label: 'Naslov',
          type: 'tipTap',
        },
        {
          name: 'slider_desc',
          label: 'Description',
          type: 'tipTap',
        },
        {
          name: 'Link',
          label: 'Link',
          type: 'link',
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        loopedSlides: 1,
        spaceBetween: 1,
        initialSlide: 1,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
        effect: 'fade',
        speed: 800,
        fadeEffect: {
          crossFade: true,
        },
        on: {
          slideChange: () => {
            this.transitionPagination()
            if (this.heroSwiper) {
              this.activeSlide = this.heroSwiper.activeIndex
            }
          },
        },
      },
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: this.heroImage,
        },
      ],
    }
  },
  computed: {
    heroImage() {
      let heroImage = ''
      if (
        this.$parent &&
        this.$parent.componentData &&
        this.$parent.componentData.linkMeta &&
        this.$parent.componentData.linkMeta.heroImageSlider &&
        this.$parent.componentData.linkMeta.heroImageSlider.length &&
        this.$parent.componentData.linkMeta.heroImageSlider[0].slider_image &&
        this.$parent.componentData.linkMeta.heroImageSlider[0].slider_image
          .newPath
      ) {
        heroImage =
          this.$parent.componentData.linkMeta.heroImageSlider[0].slider_image
            .newPath
      }
      return heroImage
    },
    consultingIndex() {
      if (this.slides.length) {
        return this.slides.findIndex(
          (el) =>
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'konzultantskeusluge' ||
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'consultingservices'
        )
      }
      return 0
    },
    racunovodstveneUslugeIndex() {
      if (this.slides.length) {
        return this.slides.findIndex(
          (el) =>
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'računovodstveneusluge' ||
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'accountingservices'
        )
      }
      return 1
    },
    euFondsIndex() {
      if (this.slides.length) {
        return this.slides.findIndex(
          (el) =>
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'eufondovi' ||
            el.slider_title
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace(' ', '')
              .toLowerCase() === 'eufonds'
        )
      }
      return 2
    },
  },
  watch: {
    $isInEditMode: {
      handler(v) {
        if (!v) {
          this.inialGetData()
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setScrollPos()
      if (
        window.innerHeight >
        document.querySelector('#Hero').clientHeight + 70
      ) {
        this.isOnTop = true
        this.disableScroll = true
      }
    })
    setTimeout(() => {
      this.setScrollPos()
    }, 1000)
    if (!this.disableScroll) {
      window.addEventListener('scroll', this.setScrollPos)
    }
    window.addEventListener('resize', this.setScrollPos)
    this.inialGetData()
  },
  beforeDestroy() {
    if (window && window.removeEventListener) {
      window.removeEventListener('scroll', this.setScrollPos)
      window.removeEventListener('resize', this.setScrollPos)
    }
  },

  methods: {
    setScrollPos() {
      const button = document.querySelector('.hero--eu')
      if (button) {
        button.style.top = `${window.innerHeight - button.clientHeight}px `
      }
      if (window.scrollY > 10) {
        this.isOnTop = false
      } else {
        this.isOnTop = true
      }
    },
    inialGetData() {
      if (this.$refs && this.$refs.editorList) {
        if (this.$refs.editorList.items && this.$refs.editorList.items.length) {
          this.slides = [...this.$refs.editorList.items]
        }
      }
    },
    slideTo(n) {
      this.heroSwiper.slideTo(n)
    },
    transitionPagination() {
      this.transition = true
      setTimeout(() => {
        this.transition = false
      }, 400)
    },
  },
}
