
import EditorImage from 'Editors/EditorImage'
import EditorList from 'Editors/EditorList'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorListItem from 'Editors/EditorListItem'
export default {
  components: {
    EditorImage,
    EditorList,
    EditorTipTap,
    EditorListItem,
  },
  data() {
    return {
      list: [],
      youtube: '',
      videoPlaying: false,
      myPlayer: null,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov',
          type: 'textarea',
        },
      ],
      // eslint-disable-next-line no-useless-escape
      reg: /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi
    }
  },
  head() {
    return {
      script: [
        {
          src: 'https://www.youtube.com/iframe_api',
          async: true,
          defer: true,
        },
      ], // --> Load scripts if needed
    }
  },
  watch: {
    $isInEditMode(v) {
      if (!v) {
        this.initialSetup()
      }
    },
  },
  mounted() {
    this.initialSetup()
  },
  beforeDestroy() {
    this.destroyVideo()
  },
  methods: {
    initialSetup() {
      if (this.$refs && this.$refs.youtube) {
        this.youtube = this.$refs.youtube.sectionMeta.youtubeLink
      }
      if (this.$refs && this.$refs.list) {
        if (this.$refs.list.items && this.$refs.list.items.length) {
          this.list = [...this.$refs.list.items]
        }
      }
    },
    loadVideo(id) {
      if (!id) return false
      // eslint-disable-next-line
      // eslint-disable-next-line no-undef
      const videoID = id.split(this.reg)[1]
      if (!videoID) {
        alert('Dogodila se greska!')
        return false
      }
      // eslint-disable-next-line no-undef
      this.myPlayer = new YT.Player(this.$refs.player, {
        height: '',
        width: '',
        videoId: videoID,
        events: {
          onReady: event => {
            this.videoPlaying = true
            event.target.playVideo()
          }
        }
      })
    },
    destroyVideo() {
      if (this.myPlayer) {
        this.myPlayer.destroy()
        this.videoPlaying = false
      }
    }
  }
}
