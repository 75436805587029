import { render, staticRenderFns } from "./PageContentSection.vue?vue&type=template&id=3078777a&scoped=true"
import script from "./PageContentSection.vue?vue&type=script&lang=js"
export * from "./PageContentSection.vue?vue&type=script&lang=js"
import style0 from "./PageContentSection.vue?vue&type=style&index=0&id=3078777a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3078777a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default})
