
import EditorList from 'Editors/EditorList'

export default {
  components: {
    EditorList,
  },
  data() {
    return {
      list: [],
      filteredList: [],
      limit: 4,
      loading: false,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'tipTap',
        },
        {
          name: 'client',
          label: 'Naziv klijenta',
          type: 'text',
        },
        {
          name: 'program',
          label: 'Naziv programa',
          type: 'text',
        },
        {
          name: 'price',
          label: 'Ukupna vrijednost projekta',
          type: 'text',
        },
      ],
    }
  },
  mounted() {
    this.loadingSimulation()
    if (this.$refs.projectsMain && this.$refs.projectsMain.items && this.$refs.projectsMain.items.length) {
      this.list = [...this.$refs.projectsMain.items]
      this.filteredList = [...this.list]
      this.filteredList = [...this.filteredList.slice(0, this.limit)]
    }

  },
  methods: {
    loadMore() {
      this.loadingSimulation()
      this.limit += 4;
      this.filteredList = [...this.list.slice(0, this.limit)]

    },
    loadingSimulation() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 800)
    }
  }
}
