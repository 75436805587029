import { render, staticRenderFns } from "./BroshureModal.vue?vue&type=template&id=130103e4&scoped=true"
import script from "./BroshureModal.vue?vue&type=script&lang=js"
export * from "./BroshureModal.vue?vue&type=script&lang=js"
import style0 from "./BroshureModal.vue?vue&type=style&index=0&id=130103e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130103e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerCustomForm: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js').default})
