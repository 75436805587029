
export default {
  data() {
    return {
      goal: 'https://recupero.hr/media/36/206859/original/vauceri-brosura-pdf.png/DL9Ehl7..r5Tb-qdFoVWqfAd3T1VImnOLOb4SlNwlCe8V~~36',
      checkbox: false
    }
  },
  mounted() {
    this.$watch(() => {
        return this.$refs.customForm && this.$refs.customForm.errorMessageObject
      },
      (obj) => {
        if (obj && !Object.values(obj).every(x => x === '')) {
          if (this.$i18n.locale === 'hr') {
            this.$toast.error('Potrebno je ispuniti sva obavezna polja prije preuzimanja brošure.')
          } else {
            this.$toast.error('All required fields are required before downloading the brochure.')
          }
        }
      },
      {
        deep: true
      }
    )
    setTimeout(() => {
      if (this.$refs.customForm && this.$refs.customForm.customForm && this.$refs.customForm.customForm.goal && this.$refs.customForm.customForm.goal.length) {
        // this.goal = this.$refs.customForm.customForm.goal
      }
    }, 1000)
  },
  methods: {

    formSubmitted() {
      if (this.goal) {
        window.open(this.goal, '_blank')
      } else {
        this.$toast.error('Nije postavljena brošura za download')
      }
      this.$emit('close')
    }
  }
}
