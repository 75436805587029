
import EditorTipTap from 'Editors/EditorTipTap'

export default {
  components: {
    EditorTipTap,
  },
  data() {
    return {
      list: [],
      sliderKey: 0,
      sliderUpdater: 1234,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'desc',
          label: 'Opis',
          type: 'textarea',
        },
      ],
      swiperOptions: {
        slidesPerView: 1.34,
        loopedSlides: 1,
        spaceBetween: 10,
        initialSlide: 0,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
        breakpoints: {
          1024: {
            slidesPerView: 2.64,
            spaceBetween: 40,
          },
        },
      }
    }
  },
  watch: {
    $isInEditMode(v) {
      if (!v) {
        this.getData()
        setTimeout(() => {
          this.aktivnosti.updateSlides()
        }, 1000)
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.$refs.aktualnostiList && this.$refs.aktualnostiList.items && this.$refs.aktualnostiList.items.length) {
        this.list = [...this.$refs.aktualnostiList.items]
      }
    }
  }
}
