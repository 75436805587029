
import EditorImage from 'Editors/EditorImage'

export default {
  components: {
    EditorImage,
  },
  data() {
    return {
      inView: false,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'tipTap',
        },
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '400x400',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
      swiperOptions: {
        slidesPerView: 1.2,
        loopedSlides: 1,
        spaceBetween: 10,
        initialSlide: 0,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
        breakpoints: {
          768: {
            slidesPerView: 2.34,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3.25,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    }
  },
}
