var render, staticRenderFns
import script from "./AboutUsPersonelSection.vue?vue&type=script&lang=js"
export * from "./AboutUsPersonelSection.vue?vue&type=script&lang=js"
import style0 from "./AboutUsPersonelSection.vue?vue&type=style&index=0&id=783655c1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783655c1",
  null
  
)

export default component.exports