var render, staticRenderFns
import script from "./AboutUsHeroSection.vue?vue&type=script&lang=js"
export * from "./AboutUsHeroSection.vue?vue&type=script&lang=js"
import style0 from "./AboutUsHeroSection.vue?vue&type=style&index=0&id=4fa2dc02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa2dc02",
  null
  
)

export default component.exports