

import EditorTipTap from 'Editors/EditorTipTap'
import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    EditorTipTap,
  },
  mixins: [inViewport],
  data() {
    return {
      isVisible: false,
      endVal: 2000,
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov',
          type: 'text',
        },
        {
          name: 'number',
          label: 'Number',
          type: 'text',
        }
      ],
      optionsCounter: {
        useEasing: true,
        begin: false,
      },
    }
  },
  watch: {
    'inViewport.now': {
      handler(visible) {
        if(visible) {
          if(this.$refs.count && this.$refs.count.length && !this.optionsCounter.begin) {
            for(const i of this.$refs.count) {
              i.reset();
              setTimeout(() => {
                i.start()
              }, 500)
            }
            this.optionsCounter.begin = true
          }
        }
      }
    }
  },
}
