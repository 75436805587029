
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'
import EditorListItem from 'Editors/EditorListItem'
import BroshureModal from '~/components/modals/BroshureModal'

export default {
  components: {
    EditorTipTap,
    EditorList,
    EditorListItem,
    BroshureModal,
  },
  data() {
    return {
      showForm: false,
      inView: false,
      options: [
        {
          name: 'title',
          label: 'Naslov (*Obavezno)',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov (*Obavezno)',
          type: 'tipTap',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
  watch: {
    showForm(v) {
      if (v) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
  },
}
