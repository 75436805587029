
import EditorList from 'Editors/EditorList'

export default {
  components: {
    EditorList,
  },
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'desc',
          label: 'Opis',
          type: 'textarea',
        },
        {
          name: 'icon',
          label: 'Icon u SVG',
          type: 'textarea'
        }
      ],
    }
  },
}
