
/* eslint-disable no-lonely-if */
import EditorLink from 'Editors/EditorLink'
import EditorTipTap from 'Editors/EditorTipTap'
import BroshureModal from "~/components/modals/BroshureModal"

export default {
  components: {
    EditorTipTap,
    EditorLink,
    BroshureModal,
  },
  data() {
    return {
      list: [],
      listFiltered: [],
      activeCategory: '',
      filterList: [],
      sliderKey: 0,
      sliderUpdater: 1234,
      options: [
        {
          name: 'title',
          label: 'Naslov (*Obavezno)',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov (*Obavezno)',
          type: 'text',
        },
        {
          name: 'date',
          label: 'Datum (npr. 23 LIS 2022.) (*Obavezno)',
          type: 'text',
        },
        {
          name: 'category',
          label: 'Kategorija (*Obavezno)',
          type: 'text',
        },
        {
          name: 'categoryDesc',
          label: 'Opis kategorije za filter',
          type: 'textarea',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
      swiperOptionsTabs: {
        slidesPerView: 'auto',
        loopedSlides: 1,
        spaceBetween: 0,
        freeMode: true,
        initialSlide: 0,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
      },
      swiperOptions: {
        slidesPerView: 1.15,
        loopedSlides: 1,
        spaceBetween: 10,
        initialSlide: 0,
        updateOnImagesReady: true,
        loop: false,
        observer: true,
        observeParents: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        breakpoints: {
          681: {
            slidesPerView: 1.7,
            spaceBetween: 20
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 2.34,
            spaceBetween: 40
          },
        }
      }
    }
  },
  watch: {
    $isInEditMode(v) {
      if (!v) {
        this.initialSetup()
      }
    },
    activeCategory(v) {
      if (v && this.list.length) {
        this.listFiltered = this.list.filter((el) => el.category === v)
        this.sliderKey++
        setTimeout(() => {
          this.aktivnosti.update()
        }, 1000)
      }
    }
  },
  mounted() {
    this.initialSetup()
  },
  methods: {
    returnDate(date) {
      if (!date) return ''
      let variableSpace
      let variableDot
      let finalDate
      variableDot = date.split('.')
      variableSpace = date.split(' ')
      if (variableDot.length === 3) {
        variableDot = {
          firstDay: variableDot[0] || 'dan',
          otherDay: `${variableDot[1]} ${variableDot[2]}` || 'datum'
        }
        finalDate = variableDot
      } else {
        if (variableSpace && variableSpace.length) {
          variableSpace = {
            firstDay: variableSpace[0] + '.' || 'dan',
            otherDay: `${variableSpace[1]} ${variableSpace[2]}` || 'datum'
          }
          finalDate = variableSpace
        }
      }
      return  finalDate
    },
    initialSetup() {
      if (this.$refs.aktualnostiList && this.$refs.aktualnostiList.items && this.$refs.aktualnostiList.items.length) {
        this.list = [...this.$refs.aktualnostiList.items]
        // eslint-disable-next-line array-callback-return
        this.list.filter((el, index) => {
          if(el && !el.category) {
            this.list.splice(index, 1)
          }
        })
        this.filterList = [...this.list].map(el => el.category).reduce(function (a, b) {
          if (!a.includes(b) && b && b.length) a.push(b);
          return a;
        }, []).sort();
        if (this.list[0].category && this.list[0].category.length) {
          this.activeCategory = this.list[0].category
        }
      }
      setTimeout(() => {
        this.aktivnosti.update()
      }, 1000)
    },
    returnCategoryDesc() {
      if (this.list && this.list.length === 0) return false
      let index = 0
      index = this.list.findIndex(el => el.category === this.activeCategory)
      if (index >= 0) {
        return this.list[index].categoryDesc
      }
      return false
    },
    slideSlider(filter) {
      this.activeCategory = filter
    }
  }
}
