
import ControllerCustomForm from 'Controllers/ControllerCustomForm'

export default {
  components: {
    ControllerCustomForm,
  },
  data() {
    return {
      nameAndSurname: '',
      name: '',
      surname: '',
      email: '',
      firm: '',
      phone: '',
      message: '',
      checkbox: false,
      isSend: false,
      loading: false,
    }
  },
  watch: {
    firm(text) {
      if (text && text.length) {
        this.$set(this.$refs.customForm.form, 'name', text)
      }
    },
    nameAndSurname(text) {
      if (text && text.length === 0 || text && false) {
        this.name = ''
        this.surname = ''
        return false
      }
      if (text && text.length && text.includes(' ')) {
        const nameArray = text.split(' ')
        if (nameArray && nameArray.length && nameArray.length === 2) {
          this.name = nameArray[0]
          this.surname = nameArray[1]
        } else if (nameArray && nameArray.length && nameArray.length > 2) {
          this.name = nameArray[0]
          this.surname = nameArray.splice(1, nameArray.length - 1).join(' ')
        }
      } else {
        this.name = ''
        this.surname = ''
      }
    },
  },
  beforeDestroy() {
    if (document) {
      document.body.style.overflow = ''
    }
  },
  mounted() {
    this.$watch(() => {
        return this.$refs.customForm && this.$refs.customForm.errorMessageObject
      },
      (obj) => {
        if (obj && !Object.values(obj).every(x => x === '')) {
          if (this.$i18n.locale === 'hr') {
            this.$toast.error('Potrebno je ispuniti sva obavezna polja prije slanja podataka.')
          } else {
            this.$toast.error('All required fields are required before submitting data.')
          }
        }
      },
      {
        deep: true
      }
    )
    if (document) {
      document.body.style.overflow = 'hidden'
    }
    this.$watch(() => {
        if (this.$refs.customForm) {
          return this.$refs.customForm.form.email
        }
      },
      (v) => {
        this.email = v
      }
    )
    this.$watch(() => {
        if (this.$refs.customForm) {
          return this.$refs.customForm.form.phone
        }
      },
      (v) => {
        this.phone = v
      }
    )
    this.$watch(() => {
        if (this.$refs.customForm) {
          return this.$refs.customForm.form.note
        }
      },
      (v) => {
        this.message = v
      }
    )
    this.$watch(() => {
        if (this.$refs.customForm) {
          return this.$refs.customForm.form.fullName
        }
      },
      (v) => {
        this.nameAndSurname = v
      }
    )
  },
  methods: {
    async sendForm() {
      this.loading = true
      await this.send()
    },
    async send() {
      if (this.checkErrorObject()) {
        if (this.$i18n.locale === 'hr') {
          this.$toast.error('Popunite sva obavezna polja i pokušajte ponovno!')
        } else {
          this.$toast.error('Fill in all required fields and try again!')
        }
        return false
      }
      this.isSend = true
      const obj = {
        name: this.firm || 'Defaultni string nije unešen',
        notes: this.message,
        contactFirstName: this.name,
        contactLastName: this.surname,
        contactEmail: this.email,
        contactPhone: this.phone
      }
      await this.$axios({
        url: '/api/v1/lead/company/create/web',
        method: 'POST',
        data: {...obj}
      }).then(res => {
        this.loading = false
        if (this.$i18n.locale === 'hr') {
          this.$toast.success('Uspješno ste poslali molbu za vaučer!')
        } else {
          this.$toast.success('You have successfully submitted a voucher application!')
        }
        this.$emit('close')
        this.isSend = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        if (this.$i18n.locale === 'hr') {
          this.$toast.error('Dogodila se greška prilikom slanja poruke, provjerite sva polja i pokušajte ponovno!')
        } else {
          this.$toast.error('There was an error sending the message, check all the fields and try again!')
        }
        this.isSend = false
      })
    },

    checkErrorObject() {
      if (this.$refs.customForm && this.$refs.customForm.errorMessageObject) {
        if (this.email === null || this.phone === null || this.nameAndSurname.length === 0) {
          return true
        }
        if (
          this.$refs.customForm.form.acceptedConsents.length !== this.$refs.customForm.requiredConsentsUniqueNumbers.length ||
          this.email && this.email.length === 0 &&
          this.phone && this.phone.length === 0 &&
          this.nameAndSurname.length === 0
        ) {
          return true
        }
      }
      return false
    }
  }
}
