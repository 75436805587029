
import EditorTipTap from 'Editors/EditorTipTap'
import EditorImage from 'Editors/EditorImage'
import EditorList from 'Editors/EditorList'
import DateFormatter from "@/mixins/DateFormatter";

export default {
  components: {
    EditorTipTap,
    EditorImage,
    EditorList,
  },
  mixins: [DateFormatter],
  data() {
    return {
      list: [],
      options: [
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
          size: '305x224',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
    }
  },
  computed: {
    previouseLink() {
      if (this.list.length) {
        return this.list[0].link
      }
      return null
    },
    nextLink() {
      if (this.list.length) {
        return this.list[1].link
      }
      return null
    }
  },
  mounted() {
    if (this.$refs.listaSinglice) {
      this.list = [...this.$refs.listaSinglice.items]
    }
  }
}
