import { render, staticRenderFns } from "./AboutUsTextSection.vue?vue&type=template&id=04428fe4&scoped=true"
import script from "./AboutUsTextSection.vue?vue&type=script&lang=js"
export * from "./AboutUsTextSection.vue?vue&type=script&lang=js"
import style0 from "./AboutUsTextSection.vue?vue&type=style&index=0&id=04428fe4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04428fe4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default})
